.mt-m-FlowBox {
    margin: 60px 0;

    &-head {
        display: block;
        text-align: center;
        margin-bottom: 40px;

        &-title {
            @include fs(35px, 42px);
            font-family: $f-museoSlab-700;
            color: $c-grey-dark;

            small {
                display: block;
                @include fs(40px, 51px);
                font-family: $f-halohandletter;
                color: $c-red;
                transform: rotate(-2deg);
                margin-bottom: -10px;

                &::before {
                    display: inline-block;
                    margin-right: 3px;
                    font-family: $f-icons;
                    content: "\e945";
                    font-size: 2rem;
                }
            }
        }
        &-social {
            margin-top: 15px;
            display: flex;
            align-items: center;
            justify-content: center;
            &-item {
                @include fs(35px, 35px);
                & + & {
                    margin-left: 12px;
                }

                a {
                    @include transition(ease-in 0.25s);
                    &:hover {
                        color: $c-red;
                    }
                }
            }
        }
    }

    .bzfy-feed-banner {
        display: none;
    }

    .bzfy-o-container {
        width: 1560px;
        max-width: 100% !important;

        .bzfy-icon--arrow {
            &-left,
            &-right {
                position: relative;
                svg {
                    display: none;
                }

                &::before {
                    font-family: $f-icons;
                    content: "\e942";
                    font-size: 30px;
                    color: #000;
                    position: relative;
                    z-index: 2;
                }

                &::after {
                    content: '';
                    height: 100px;
                    width: 100px;
                    max-width: initial;
                    border-radius: 100%;
                    color: $c-black;
                    z-index: 1;
                    position: absolute;
                    @include center-abs-y();
                    cursor: pointer;
                    display: block;
                }
            }

            &-left {
                left: -15px;
                &::before {
                    transform: scaleX(-1);
                    left: 20px;
                }
                &::after {
                    background: linear-gradient(270deg, rgba($c-white, 0.2) 0%, $c-white 100%);
                }
            }

            &-right {
                right: -15px;
                &::before {
                    right: 20px;
                }
                &::after {
                    background: linear-gradient(270deg, $c-white 0%, rgba($c-white, 0.2) 100%);
                }
            }
        }
    }

    .bzfy-c-carousel-slider {
        &-wrapper__inner {
            padding: 0 13px!important;
        }
    }

    @include breakpoint(only-lg) {
        margin: 40px 0;
    }

    @include breakpoint(only-md) {
        &-head {
            &-title {
                @include fs(25px, 46px);
                small {
                    @include fs(36px, 31px);
                }
            }
        }

        .bzfy-t-feed-carousel .bzfy-c-mobile-carousel-control {
            left: 0;
        }
    }
}
