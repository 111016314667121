.mt-c-HomeFavorites {
    position: relative;
    margin: 60px 0;
    padding-top: 60px;
    padding-bottom: 80px;
    background: $c-pink-light;

    &::after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 12px;
        background: var(--season-pattern-2);
        content: ' ';
    }

    &-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 1200px;
        max-width: 100%;
        margin: 0 auto;

        &-sliderText {
            min-width: 0;
            flex: 0 1 55%;

            .slick-initialized + .mt-c-HomeFavorites-content-sliderText-nav {
                display: flex;
            }
        }
        &-sliderImages {
            position: relative;
            flex: 0 1 33%;
            max-width: 400px;
            height: auto;
            @include fs0();

            .slick-list {
                overflow: visible;
            }
            .slick-slide > div {
                width: 100%;
            }
            li {
                position: relative;

                &::before {
                    display: block;
                    height: 0;
                    padding-bottom: 100%;
                    content: ' ';
                }
            }
            &-image1 {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
            &-image2 {
                position: absolute;
                bottom: 0;
                left: -70px;
                max-width: 170px;
                height: auto;
            }
            a {
                cursor: pointer;
            }
        }
        &-nav {
            display: none;
        }
    }

    &-nav {
        display: none;
        position: relative;
        align-items: center;
        margin-top: 30px;

        .slick-arrow {
            position: static;
            margin: 0;
            width: 30px;
            height: auto;
            background: transparent;
            transform: none;
            cursor: pointer;

            &::before {
                @include fs(30px, 25px);
            }
        }
        .slick-dots {
            margin-top: 0;
            font-size: 0;
            line-height: 0;
        }
        .slick-dots button {
            background-color: $c-red;
        }
        .slick-dots > li:not(.slick-active) button {
            opacity: 0.2;
        }
        &-left.slick-arrow {
            transform: scaleX(-1);
        }
        &-dots {
            padding: 0 25px;
        }
    }

    @include breakpoint(only-lg) {
        padding-top: 40px;
        padding-bottom: 60px;

        &-content {
            display: block;
            width: 650px;
            margin: 0 auto;

            &-sliderText {
                margin-bottom: 20px;
                .slick-initialized + .mt-c-HomeFavorites-content-sliderText-nav {
                    display: none;
                }
            }
            &-sliderImages {
                max-width: 100%;
                flex: initial;

                li {
                    &:before {
                        display: none;
                    }
                }

                &-image1 {
                    max-width: initial;
                    position: relative;
                    left: initial;
                    top: initial;
                    margin: 0 auto;
                }

                &-image2 {
                    bottom: 0;
                    left: -5px;
                }
            }
            &-nav {
                display: flex;
                justify-content: center;
            }
        }
    }

    @include breakpoint(only-md) {
        &-content {
            &-sliderImages {
                li {
                    padding-left: 50px;
                }

                &-image2 {
                    left: 0;
                    max-width: 125px;
                }
            }
        }
    }
}

.mt-c-FavoritesTextSlide {
    &-head {
        margin-bottom: 25px;
        display: flex;
        align-items: center;

        &-image {
            position: relative;
            margin-left: 20px;
            width: 120px;
            height: 120px;
            min-width: 12px;

            &-container {
                border-radius: 50%;
                overflow: hidden;
                display: block;
                position: relative;
                z-index: 1;
                width: 100%;
                height: 100%;
                img {
                    min-width: 100%;
                    width: auto;
                    min-height: 100%;
                    height: auto;
                    @include center-abs-xy();
                    object-fit: cover;
                }
            }

            &-icon {
                z-index: 2;
                position: absolute;
                top: 0;
                left: -10px;
                @include fs(45px, 30px);
                color: $c-red;
            }
        }
        &-text {
            padding-left: 25px;

            &-title {
                font-family: $f-halohandletter;
                color: $c-new-red;
                @include fs(50px, 55px);
                letter-spacing: 0;
                display: flex;
                align-items: center;
                flex-wrap: wrap;

                .icon-favoris {
                    @include fs(30px, 35px);
                    margin: 0 10px;
                }
                strong {
                    color: $c-grey-dark;
                    font-family: $f-museoSlab-700;
                    @include fs(35px, 40px);
                }
            }
            &-subtitle {
                font-family: $f-avenir;
                @include fs(16px, 20px);
                color: $c-grey-dark;
            }
        }
    }
    &-text {
        margin-bottom: 30px;

        &-intro {
            font-family: $f-avenir-medium;
            @include fs(20px,26px);
            margin-bottom: 10px;
            color: $c-black;
        }
        p {
            @include fs(16px, 20px);
            color: $c-grey-1;
        }
    }

    @include breakpoint(only-lg) {
        &-head {
            margin-bottom: 20px;

            &-text {
                margin-bottom: 20px;
                &-title {
                    @include fs(35px, 25px);
                }
            }
            &-image {
                margin-left: 15px;
                width: 90px;
                height: 90px;
                min-width: 90px;

                &-icon {
                    top: -10px;
                    left: -10px;
                    @include fs(37px, 40px);
                }
            }
            &-text {
                padding-left: 20px;

                &-title {
                    strong {
                        @include fs(25px, 30px);
                    }
                }
            }
        }
        &-text {
            margin-bottom: 15px;
        }
    }
}
