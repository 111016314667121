.mt-c-HomeVideo {
    text-align: center;
    margin: 6rem 0;

    &-head {
        width: 720px;
        max-width: 100%;
        margin: 0 auto 4rem;
    }
    &-title {
        margin-bottom: 2rem;
    }
    &-intro {

    }
    &-thumbnail {
        position: relative;
        width: 1520px;
        max-width: 100%;
        height: auto;
        font-size: 0;
        line-height: 0;
        background: #212121;
        margin: 0 auto;

        &::before {
            display: block;
            width: 100%;
            height: 0;
            padding-bottom: 31.41%; // 1560x490px
            content: '';
        }
        &::after {
            position: absolute;
            z-index: 3;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #212121;
        }
        img, iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        img {
            object-fit: cover;
            z-index: 2;
        }
        iframe {
            z-index: 1;
        }
        button {
            position: absolute;
            z-index: 4;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%) scale(1);
            width: 80px;
            height: 77px;
            background: url('../images/icon/patate-pink.svg');
            background-size: 100% 100%;
            font-size: 1.7rem;
            color: #fff;
            transition: transform .3s;

            &:hover {
                transform: translate(-50%, -50%) scale(1.2);
            }
        }
    }

    @include breakpoint(only-lg) {
        margin: 4rem 0;

        &-head {
            margin-bottom: 2rem;
        }
        &-title {
            margin-bottom: 1rem;
        }
        &-thumbnail {
            &::before {
                padding-bottom: 56.25%; // 560x315px
            }
        }
    }
}
