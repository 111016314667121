.mt-c-ReviewSlider {
    margin: 6rem auto;
    position: relative;
    padding: 0 2rem;
    text-align: center;

    &-title {
        margin-bottom: 4rem;
    }
    &-badge {
        position: absolute;
        top: -2rem;
        left: 50%;
        margin-left: 370px;
        width: 120px;
        height: 120px;
        border-radius: 50%;
        background: $c-red;
        color: white;
        padding-top: 3rem;

        &-note {
            font-family: $f-avenir-heavy;
            font-size: 3.5rem;
            color: #F8D3D9;
            margin-bottom: 1rem;

            strong {
                color: white;
            }
        }
        &-count {
            font-size: 1.4rem;
            font-family: $f-avenir;
        }
    }
    &-list {
        width: 1280px;
        max-width: 100%;
        margin: 6rem auto;
        display: flex;
        overflow: hidden;
        //padding: 0 2.5rem;

        .slick-arrow.slick-next {
            right: 0;
        }
        .slick-arrow.slick-prev {
            left: 0;
        }
        .slick-slide {
            > * {
                flex: 1;
            }
        }
        &:not(.slick-initialized) .mt-c-Review {
            min-width: 33.33%;
        }
    }

    @include breakpoint(only-lg) {
        margin: 3rem 0 4rem;

        &-title {
            margin-bottom: 3rem;
        }
        &-badge {
            display: none;
        }
    }
}

.mt-c-Review {
    &-stars {
        margin-bottom: 2rem;
        color: #FFB800;

        .star-off {
            color: #F8D5CA;
        }
    }
    &-text {
        font-size: 1.6rem;
        line-height: 2rem;
        margin-bottom: 1.5rem;
    }
    &-date {
        font-size: 1.4rem;
        line-height: 2rem;
        font-family: $f-museoSlab-500;
    }
}
